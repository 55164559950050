import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge, Box, IconButton, List, ListItem, ListItemText, Modal, Typography } from '@mui/material';
import { GetNotificationsForDillerProducts } from 'hooks/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import audio from '../../assest/ring.mp3';

const NotificationButton = () => {
	//
	const DEFAULT_BADGE_COLOR = '#FDD835';
	const VIEWED_BADGE_COLOR = 'grey';
	const [openModal, setOpenModal] = useState(false);
	const [badgeColor, setBadgeColor] = useState(DEFAULT_BADGE_COLOR);
	const user = useSelector(({ auth }) => auth.user);
	const [notifications, setNotifications] = useState([]);
	const [totalNotifications, setTotalNotifications] = useState(0);
	const audioPlay = new Audio(audio);
	const tableQuery = {
		dillerId: user?.id,
		sortBy: 'total_count',
		order: 'desc'
	};

	const { data: fetchedDatas, refetch } = GetNotificationsForDillerProducts(tableQuery, { enabled: false });
	useEffect(() => {
		//
		if (fetchedDatas?.data) {
			setNotifications(fetchedDatas.data.data);
			setTotalNotifications(fetchedDatas.data.total);
			localStorage.setItem('notificationsData', JSON.stringify(fetchedDatas.data));
		}
	}, [fetchedDatas]);

	useEffect(() => {
		const lastVisit = localStorage.getItem('lastVisitDate');
		const today = new Date().toISOString().split('T')[0];
		const storedData = localStorage.getItem('notificationsData');
		const lastViewedDate = localStorage.getItem('lastViewedDate');

		if (lastVisit !== today) {
			setBadgeColor(DEFAULT_BADGE_COLOR);

			refetch();
			audioPlay.play();

			localStorage.setItem('lastVisitDate', today);
		} else {
			if (lastViewedDate === today) {
				setBadgeColor(VIEWED_BADGE_COLOR);
			}

			if (storedData) {
				const parsedData = JSON.parse(storedData);

				setNotifications(parsedData.data);

				setTotalNotifications(parsedData.total);
			} else {
				refetch();

				localStorage.setItem('lastVisitDate', today);
			}
		}
	}, []);

	const handleClick = () => {
		setOpenModal(true);
		setBadgeColor(VIEWED_BADGE_COLOR);

		const today = new Date().toISOString().split('T')[0];
		localStorage.setItem('lastViewedDate', today);
	};

	const handleCloseModal = () => setOpenModal(false);

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		maxWidth: '800px',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		borderRadius: 2,
		maxHeight: '80vh',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column'
	};

	return (
		<>
			<IconButton color="inherit" onClick={handleClick}>
				<Badge
					badgeContent={totalNotifications}
					max={999999}
					sx={{
						'& .MuiBadge-badge': {
							backgroundColor: badgeColor,
							color: 'black'
						}
					}}
				>
					<NotificationsNoneOutlinedIcon sx={{ fontSize: 20 }} />
				</Badge>
			</IconButton>

			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={modalStyle}>
					<Typography
						variant="h5"
						align="center"
						sx={{
							mb: 3,
							fontSize: {
								xs: '1.5rem',
								sm: '2rem'
							}
						}}
					>
						Рекомендуем Вам заказать эти товары на витрину.
					</Typography>

					<List
						sx={{
							overflowY: 'auto',
							flex: 1,
							width: '100%'
						}}
					>
						{notifications.length > 0 ? (
							<Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
								{notifications.map((notification, index) => (
									<ListItem
										key={index}
										sx={{
											borderBottom: '1px solid rgba(0,0,0,0.12)'
										}}
									>
										<ListItemText
											primaryTypographyProps={{ fontSize: '1.2rem', fontWeight: 'bold' }}
											primary={`${index + 1}. ${notification?.product?.name} - ${
												notification?.product?.model
											}  - есть на складе!`}
											sx={{
												'& .MuiTypography-root': {
													fontSize: {
														xs: '1rem',
														sm: '1.2rem'
													}
												}
											}}
										/>
									</ListItem>
								))}
							</Box>
						) : (
							<Typography
								variant="body1"
								align="center"
								sx={{
									fontSize: {
										xs: '1rem',
										sm: '1.2rem'
									}
								}}
							>
								Нет новых уведомлений
							</Typography>
						)}
					</List>
				</Box>
			</Modal>
		</>
	);
};

export default NotificationButton;
