import { authRoles } from 'app/auth';
import { lazy } from 'react';

const DataModeratorsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.diller,
	routes: [
		{
			path: '/apps/users/diller-helper',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataModeratorsAppConfig;
