import { authRoles } from 'app/auth';
import { lazy } from 'react';

const OrderAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.salesAgent, ...authRoles.diller, ...authRoles.dillerHelper],
	routes: [
		{
			path: '/apps/sales-agents/orders',
			exact: true,
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/sales-agents/orders/:id',
			exact: true,
			component: lazy(() => import('./products'))
		}
	]
};

export default OrderAppConfig;
