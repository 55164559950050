import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';
import { api } from 'hooks/api/main-request/base';

const TELEGRAM_BOT_TOKEN = '7683243936:AAHNR-LVUzFnhdB2wO9jNGcp-LMOizV-NmM';
const TELEGRAM_CHAT_ID = '-1002411687191';

// ✅ Send errors to the backend
const sendErrorToBackend = async (error, componentStack) => {
	const isAxiosError = axios.isAxiosError(error);

	const payload = {
		message: error.message,
		name: 'Frontend Error',
		stack: isAxiosError ? error.stack : componentStack,
		path: window.location.href,
		extra: ''
	};

	try {
		await api({
			method: 'POST',
			url: 'logger',
			data: JSON.stringify(payload),
			headers: { 'Content-Type': 'application/json' }
		});
	} catch (err) {
		console.error('Failed to send error to backend:', err);
	}
};

const sendErrorToTelegram = async (error, componentStack) => {
	const isAxiosError = axios.isAxiosError(error);
	const errorType = isAxiosError ? 'API Error' : 'Frontend Error';

	const message =
		`🚨 *Error Alert* 🚨\n\n` +
		`*Message:* ${error.message}\n` +
		`*URL:* ${window.location.href}\n` +
		`*Time:* ${new Date().toISOString()}\n` +
		`*Error Type:* ${errorType}\n` +
		`*Stack:* \n\`${componentStack || error.stack}\``;

	const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

	try {
		await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ chat_id: TELEGRAM_CHAT_ID, text: message, parse_mode: 'Markdown' })
		});
	} catch (err) {
		console.error('Failed to send error to Telegram:', err);
	}
};

// ✅ Fallback UI
const GlobalErrorFallback = ({ error, resetErrorBoundary }) => {
	const handleReset = () => {
		resetErrorBoundary();
		window.location.pathname = '/';
	};

	return (
		<div style={styles.container}>
			<h2>Something went wrong.</h2>
			<p>{error.message}</p>
			<button type="button" style={styles.button} onClick={handleReset}>
				Go Home
			</button>
		</div>
	);
};

// ✅ Wrap the entire app
const GlobalErrorBoundary = ({ children }) => {
	return (
		<ErrorBoundary
			FallbackComponent={GlobalErrorFallback}
			onError={(error, info) => sendErrorToBackend(error, info.componentStack)}
		>
			{children}
		</ErrorBoundary>
	);
};

const styles = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		padding: '20px',
		color: '#721c24',
		borderRadius: '8px',
		margin: '20px auto'
	},
	button: {
		backgroundColor: '#1d2533',
		color: '#fff',
		padding: '10px 20px',
		border: 'none',
		borderRadius: '12px',
		cursor: 'pointer',
		marginTop: '10px'
	}
};

export default GlobalErrorBoundary;
