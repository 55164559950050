import { useMutation } from 'react-query';

import { api } from '../main-request/base';

// method: "post" | "get" | "put" | "patch" | "delete"
const useGeneralApiMutation = (method, url, options) =>
	useMutation(
		variables => {
			const response = api({ method, url, data: variables });
			return response;
		},
		{ ...options }
	);

export default useGeneralApiMutation;
