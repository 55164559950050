// import { logout, store } from '@app/store';
import axios from 'axios';

function newAbortSignal(timeoutMs) {
	const abortController = new AbortController();
	setTimeout(() => abortController.abort(), timeoutMs || 0);
	return abortController.signal;
}

export const api = axios.create({
	baseURL: localStorage.getItem('baseUrl') || process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
	config => {
		if (config.method === 'get') {
			config.signal = newAbortSignal(10000);
		}

		if (String(config.headers.Authorization).toLowerCase() === 'basic') {
			config.headers.Authorization = 'Basic ZnN0ZmR1c2VyOmZzdGZkcHN3ZA==';
		} else {
			const accessToken = localStorage.getItem('jwt_access_token');
			if (accessToken) {
				config.headers.Authorization = accessToken;
			}
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error?.response?.status === 406) {
			throw error;
		}

		if (error?.response) {
			sendApiErrorToBackend(error);
			return handleError(error?.response);
		}

		throw error;
	}
);

// Error handling function
function handleError(response) {
	const { status, data } = response;

	if (status && status >= 400 && status <= 600) {
		const messages = data?.message || data?.error;

		if (status === 401 || status === 426) {
			localStorage.clear();
			// store.dispatch(logoutUser());
		}

		if (typeof messages === 'string' || Array.isArray(messages)) {
			if (Array.isArray(messages)) {
				const [message] = messages;

				throw new Error(message);
			}

			throw new Error(messages);
		}

		throw Error(String(response));
	}

	if ('data' in response) return response;
}

async function sendApiErrorToBackend(error) {
	const { status, data, config } = error.response;

	const errorPayload = {
		message: data?.message || 'Unknown API Error',
		name: 'API Error',
		stack: error.stack,
		path: config.url,
		extra: JSON.stringify({
			method: config.method,
			timestamp: new Date().toISOString(),
			status
		})
	};

	try {
		await api({
			method: 'POST',
			url: 'logger',
			data: JSON.stringify(errorPayload),
			headers: { 'Content-Type': 'application/json' }
		});
	} catch (err) {
		console.error('Failed to send API error to backend:', err);
	}
}
